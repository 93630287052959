<template>
  <CollaboratorsTable/>
</template>
<script>
import CollaboratorsTable from './CollaboratorsTable';

export default {
  components: {
    CollaboratorsTable
  },
  props: {}
};
</script>