<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, email, roles, lastName, firstName, availableRoles]
    x-vue-custom-fields: [ ]
    x-vue-tags: [ ]
    x-vue-metadata: [ ]
    -->
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    model="collaborators"
  />
</template>
<script>
import { collaborators } from '@/config/descriptions/collaborators';
import { collaboratorsTexts } from '@/config/pageTexts/collaborators.json';

export default {
  name: 'CollaboratorsTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'collaborators/collaborators',
          fields: 'collaborators/fields',
          sortableFields: 'collaborators/sortableFields',
          pages: 'collaborators/pages',
          loading: 'collaborators/loading',
          resultCount: 'collaborators/resultCount',
          totalRecords: 'collaborators/totalRecords',
          originalFields: 'collaborators/originalFields',
          searchableAllFields: 'collaborators/searchableAllFields',
          success: 'collaborators/success',
          message: 'collaborators/message',
        },
        actions: {
          fields: 'collaborators/handleFields',
          delete: 'collaborators/handleDeleteCollaborators',
          reset: 'collaborators/handleClearFilter',
          query: 'collaborators/handleGetCollaborators',
        },
      },
      settings: {
        describe: { ...collaborators },
        page: { ...collaboratorsTexts },
      },
      route: {
        create: 'CreateCollaborator',
        edit: 'EditCollaborator',
        preview: 'PreviewCollaborator',
      },
    };
  },
};
</script>